<template>
  <section class="main index" v-if="!isLoading">
    <div>
      <div class="columns is-multiline">
        <div class="column is-12">
          <h1 class="is-size-3"><b>Case Study Setup</b></h1>
        </div>
        <div class="column bar">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li>
                <h3 class="is-size-6">Case Study</h3>
              </li>
              <li>
                <router-link :to="{ name: `caseStudy` }">
                  <h3 class="is-size-6">Case Study Setup</h3>
                </router-link>
              </li>
              <li>
                <h3 class="is-size-6 breadcrumb-item">Detail Case Study</h3>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12 box">
          <!-- <div class="box"> -->
          <AssessmentInformation :data="information" />
          <br /><br />
          <!-- </div>
          <div class="box"> -->
          <AssessmentThankYouPage :data="thankYouPage" />
          <br /><br />

          <!-- </div>
          <div class="box"> -->
          <CaseStudySection :data="listSection" />

          <!-- </div> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AssessmentInformation from "@/components/AssessmentDetail/AssessmentInformation";
import AssessmentThankYouPage from "@/components/AssessmentDetail/AssessmentThankYouPage";
import CaseStudySection from "@/components/CaseStudySection/IndexCaseStudySection";

export default {
  components: {
    AssessmentInformation,
    AssessmentThankYouPage,
    CaseStudySection
  },
  name: "detail-assessment",
  data() {
    return {
      // For Animated Change
      isAnimated: true,

      // For Active Step
      activeStep: 0,

      // For Loading Page
      isLoading: false,

      // For step success
      isProfileSuccess: true,

      // For Information Data
      information: {
        title: "",
        description: "",
        category: []
      },

      // For Introduction Data
      thankYouPage: {
        title: "",
        description: ""
      },

      rule: {
        endDate: "",
        startDate: ""
      },

      settings: {
        screenCaptureTime: "",
        screenShotTime: ""
      },

      listSection: {
        section: []
      },

      isUseRules: true
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    // For get assessment detail data
    async fetchData() {
      this.isLoading = true;
      const response = await this.$store.dispatch(
        "getCaseStudy",
        this.$route.params.caseStudyId
      );
      this.information = response.data.information;
      this.thankYouPage = response.data.thankYouPage;
      this.listSection.section = response.data.section;
      this.isLoading = false;
    }
  }
};
</script>
